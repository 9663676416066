<template>
  <div>
    <b-table
      :sort-desc="true"
      :items="ownersBookings"
      :fields="tableColumns"
      :tbody-tr-class="rowClass"
      primary-key="id"
      :sort-by.sync="isSortBy"
      show-empty
      empty-text="Sin registros encontrados"
      class="position-relative mb-0 minHTable"
      v-if="ownersBookings.length"
      responsive
    >
      <template #cell(invoice)="row">
        <p class="d-flex justify-content-between align-items-center">
          <span :class="row.item.invoice ? 'text-foliorsv':''">{{ row.item.invoice ? row.item.invoice : '--'}}</span>
          <b-button variant="link" class="btn-icon" :id="'popover-button-variant-'+row.item.id"><b-icon-info-circle-fill variant="secondary"/>
          </b-button>
        </p>
        <b-popover :target="'popover-button-variant-'+row.item.id" variant="primary" triggers="focus">
          <template #title>Datos de la reserva</template>
          <b>Fecha de creación:</b> {{ formatDateInfo(row.item.createdate, "es") }} <br>
          <b>Creado por:</b> {{ row.item.userName ? row.item.userName : '--' }} <br>
          <b>Tipo de origen:</b> {{ row.item.originname ? row.item.originname : '--' }} <br>
          <b>Número Localizador:</b> {{ row.item.code }} <br>
          <span v-if="row.item.benefitsUsed && row.item.benefitsUsed.length" >
			<b>{{ row.item.benefitsUsed.length === 1 ? 'Beneficio' : 'Beneficios' }}:</b> {{ stringBenefits( row.item.benefitsUsed ) }}
		 </span>
        </b-popover>
      </template>

      <template #cell(fullname)="row">
        <b class="text-nowrap">Código de Confirmación:</b> <strong :class="row.item.reservanumber ? 'reserva-number' : 'no-reserva'">{{ row.item.reservanumber ? row.item.reservanumber : 'Sin Registro'}}</strong> <br>
        <b>Vivienda: </b> <br> <span>{{row. item.fullname}} | {{ row.item.membership }}</span><br>
        <b>Huésped: </b>{{row.item.guestname}} <br>
        <!-- <b>Beneficio: </b><span v-for="(benefit, index) in row.item.benefit" :key="index"> <b-badge class="mr-1" variant="primary">{{benefit.benefit__code}} </b-badge> </span><br> -->
        <b>Tipo de Ocupante: </b>{{row.item.occupantname}}<br>
        <b>Origen de la Reserva: </b>{{row.item.origin == '2' ? "SalesCore" : row.item.originname}}<br>
        <b>Noches: </b>{{ row.item.nights }} <br>
        <b-spinner label="Loading..." variant="success" v-if="row.item.isLoading"/>
      </template>

      <!-- <template #cell(datein)="row">
        <b >Fechas:</b><br>
        <span class="dates-checkInOut">{{ row.item.datein }}<feather-icon icon="ArrowRightIcon" size="16" class="align-middle"  /> {{ row.item.dateout }}  </span>   <br>
        <b-badge variant="dark" >
          <feather-icon icon="MoonIcon" class="mr-25" />
          <span>Noches {{ row.item.nights }}</span>
        </b-badge>
      </template>   -->

      <template #cell(adults)="row">
        <div class="text-nowrap">
          Adultos: <b> {{row.item.adults}} </b> <br>
          Niños: <b> {{row.item.children}} </b>
        </div>
      </template>

      <template #cell(ishistory)="data">
        <span class="text-nowrap">
          {{ data.value ? "Sí" : "No" }}
        </span>
      </template>

      <template #cell(createdate)="row">
        <span>{{ formatDateInfo(row.item.createdate, "es") }}</span>
      </template>

      <template #cell(status)="row">
        <span class="text-nowrap">
          <div v-if="row.item.status== 4 ">
            <b-dropdown
              variant="link"
              toggle-class="p-0 mr-1"
              text="Form"
              no-caret
              right
              v-b-tooltip.hover title="Haga click para ver más detalles"
            >
            <template #button-content>
              <b-badge href="#" pill class="bg-info">
                {{row.item.statusname}} <feather-icon icon="AlertOctagonIcon"/>
              </b-badge>
            </template>

            <div v-if="row.item.internalnotes || row.item.dataAuthorization != ''">
              <div class="m-1 text-center"> <b>Detalles</b></div><hr>
              <div class="m-1">
                <b>Razón autorización: {{row.item.dataAuthorization != '' ? row.item.dataAuthorization[0].reasonAuthorization : '--' }}</b>
              </div>
              <div v-html="row.item.internalnotes ? `<b>Notas</b><br> ${row.item.internalnotes.replace(/\n/g, '<br>')}` : ''" class="m-1"></div>
            </div>
            <div v-else>
              <p class="m-1 text-danger">No se tiene información adicional</p>
            </div>
          </b-dropdown>
          </div>

          <div v-else-if="row.item.status== 2 ">
            <b-dropdown
              variant="link"
              toggle-class="p-0 mr-1"
              text="Form"
              no-caret
              right
              v-if="row.item.dataAuthorization[0]"
              v-b-tooltip.hover title="Haga click para ver más detalles"
            >
              <template #button-content>
                <b-badge href="#" pill class="bg-success">
                  {{row.item.statusname}} <feather-icon icon="CheckCircleIcon"/>
                </b-badge>
              </template>
              <div class="m-1" v-if="row.item.dataAuthorization[0]">
                <div class="mb-1 text-center"> <b>Detalles Autorización</b></div><hr>
                <p> <b>Autorizada por: </b> {{row.item.dataAuthorization[0] ? row.item.dataAuthorization[0].authorizationBy : ''}} <br> </p>
                <p> <b>Fecha: </b> {{ row.item.dataAuthorization[0] ?  formatDateInfo(row.item.dataAuthorization[0].dateAuthorization, "es") : '' }}</p>
                <p> <b>Notas: </b> {{row.item.dataAuthorization[0] ? row.item.dataAuthorization[0].noteAuthorization : ''}}<br> </p>
              </div>
            </b-dropdown>
            <b-badge pill class="bg-success" v-else>
              {{row.item.statusname}} <feather-icon icon="CheckCircleIcon"/>
            </b-badge>
          </div>

          <div v-else-if="row.item.status== 3 ">
            <b-dropdown
              variant="link"
              toggle-class="p-0 mr-1"
              text="Form"
              no-caret
              right
              v-b-tooltip.hover title="Haga click para ver más detalles"
            >
              <template #button-content>
                <b-badge pill href="#" class="bg-danger">
                  {{row.item.statusname}} <feather-icon icon="AlertCircleIcon"/>
                </b-badge>
              </template>
              <div class="m-1">
                <div class="mb-1 text-center"> <b>Detalles cancelación</b></div><hr>
                <p v-if="row.item.userCancelRequestedName"> <b>Solicitado por:</b> {{row.item.userCancelRequestedName}} <br> </p>
                <p v-if="row.item.cancelRequestNotes"><b>Solicitud: </b> {{row.item.cancelRequestNotes}}<br> </p>
                <p v-if="row.item.userCancelName"> <b>Cancelado por:</b> {{row.item.userCancelName}} <br> </p>
                <p v-if="row.item.cancelNotes"><b>Notas:</b> {{row.item.cancelNotes}}<br> </p>
                <p v-if="row.item.cancellationDate">  <b>Fecha de cancelación: </b>{{ formatDateInfo(row.item.cancellationDate, "es") }}</p>
              </div>
            </b-dropdown>
          </div>

          <div v-else-if="row.item.status== 6 && row.item.cancellationRequestDate">
            <b-dropdown
              variant="link"
              toggle-class="p-0 mr-1"
              text="Form"
              no-caret
              right
              v-b-tooltip.hover title="Haga click para ver más detalles"
            >
              <template #button-content>
                <b-badge pill href="#" class="btn-outline-danger">
                  {{row.item.statusname}} <feather-icon icon="AlertTriangleIcon"/>
                </b-badge>
              </template>
              <div class="m-1">
                <div class="mb-1 text-center"> <b>Solicitud de cancelación</b></div><hr>
                <p v-if="row.item.userCancelRequestedName"> <b>Solicitado por:</b> {{row.item.userCancelRequestedName}} <br> </p>
                <p v-if="row.item.cancellationRequestDate"> <b>Fecha: </b>{{ formatDateInfo(row.item.cancellationRequestDate, "es") }}</p>
                <p v-if="row.item.cancelRequestNotes"><b>Notas:</b> {{row.item.cancelRequestNotes}}<br> </p>
              </div>
            </b-dropdown>
          </div>

          <div v-else class="p-0 mr-1" >
            <b-badge pill :class="pendingClass(row.item.status)">
              {{row.item.statusname}} <feather-icon icon="AlertTriangleIcon" />
            </b-badge>
          </div>

        </span>
      </template>

      <template #cell(actions)="row">
        <div v-if="row.item.active" class="text-nowrap">
          <b-dropdown
            toggle-class="p-0"
            no-caret
            right
          >
            <template #button-content>
              <b-button variant="primary" size="sm">
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" style="color: white" />
              </b-button>
            </template>
            <!-- <b-dropdown-item
              :to="{ name: 'owners-Booking', params: { booking: 'booking-' + row.item.id+'-'+false } }"
              v-if="!row.item.ishistory && row.item.originname=='Web Propietarios'"
            >
              <feather-icon icon="CalendarIcon" size="12" />
              <span class="align-middle ml-50"> Reservar</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              :href="attrLink + '/FivesClub/prearrivals/' + 'datein' + '/' + '0' + '/' + row.item.id+ '/' + row.item.datein + '/' + row.item.dateout"
              target="_blank"
              rel="noopener noreferrer"
            >
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50"> Prearrivals</span>
            </b-dropdown-item>
            <b-dropdown-item
              :href="attrLink + '/Transactions/ordersdetailcouponOwner/' + row.item.id"
              target="_blank"
              rel="noopener noreferrer"
            >
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50">Cupon</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'edit-owner-booking', params: { booking: 'booking-' + row.item.id+'-'+true }, }">
              <feather-icon icon="CalendarIcon" size="12" />
              <span class="align-middle ml-50"> Detalle de reserva</span>
            </b-dropdown-item>
            <b-dropdown-item @click="observationsShow(row.item)" v-if="!can('fivesclub_owner_booking_show_booking_auth_action_button_edit')">
              <feather-icon icon="CircleIcon" size="12" />
              <span class="align-middle ml-50"> Notas Especiales</span>
            </b-dropdown-item>            
            <b-dropdown-item @click="goToCancel(row.item)" v-if="row.item.status != 3 && row.item.status != 4 && canCancelBooking().can">
              <feather-icon icon="TrashIcon" size="12" />
              <span class="align-middle ml-50"> {{ canCancelBooking().text }}</span>
            </b-dropdown-item>			
            <b-dropdown-item
              @click="addFolioShow(row.item)" v-if="row.item.status==1 || row.item.status==2 || row.item.status==5" v-can="'fivesclub_owner_booking_show_booking_reservation_list_action_button_folio_reserve'">
              <feather-icon icon="CircleIcon" size="12" />
              <span class="align-middle ml-50">Código de Confirmación</span>
            </b-dropdown-item>
            <b-dropdown-item @click="openModalAuthorize(row.item)" v-if="canAuthBookings(row.item)">
              <feather-icon icon="UnlockIcon" /> <span class="align-middle ml-50"> Autorizar Reserva</span>
            </b-dropdown-item>

            <ModalAuthorize :detail="row.item" :isLoading="isAuthorizing" @authorize-rsv-pending="autorizeRSV" />
            <ModalCancelReservation :detail="row.item" :isLoading="isCancelingRsv" :cancelRsv="cancelRsv" @cancel-rsv-owner="cancelRsvOwner" />
            <ModalAddNotes :detail="row.item" :originalnote="originalnote" @observationsDetailReserv="observationsDetail" />
            <ModalAddFolio :detail="row.item" :folioInfo="folioInfo" @folioDetail="folioDetail" />

          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-alert variant="danger" v-if="!ownersBookings.length" show>
      <div class="alert-body text-center">
        <span><strong>¡Sin registros!</strong> No se encontraron reservaciones.</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { AlertTriangleIcon,AlertCircleIcon,CheckCircleIcon  } from 'vue-feather-icons'
import ModalCancelReservation from '@/modules/fivesClub/components/catalogs/ownersBookings/list/ModalCancelReservation'
import ModalAddNotes from '@/modules/fivesClub/components/catalogs/ownersBookings/list/ModalAddNotes'
import ModalAddFolio from '@/modules/fivesClub/components/catalogs/ownersBookings/list/ModalAddFolio'
import { VBTooltip } from 'bootstrap-vue'
import { createArraysToSaveAMembership, payloadBookigOwner } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage, formatDateOnly } from '@/helpers/helpers'
import ModalAuthorize from '@/modules/fivesClub/components/catalogs/ownersBookings/list/AuthorizeBooking'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [utils, acl],
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    ownersBookings: {
      type: Array,
      required: true,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    isSortDirDesc: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModalAddNotes,
    ModalAddFolio,
    CheckCircleIcon,
    AlertTriangleIcon,
    AlertCircleIcon,
    ModalCancelReservation,
    ModalAuthorize
  },
  data() {
    return {
      attrLink: process.env.VUE_APP_IMG_SRC_API,
      isSortDir: this.isSortDirDesc,
      isSortBy: this.sortBy,
      categoriesArray: [],
      optionsDateFormat: { year: "numeric", month: "2-digit", day: "numeric" },
      isCancelingRsv: false,
      originalnote: null,
      folioInfo: null,
      isAuthorizing: false,
      cancelRsv: null
    };
  },

  computed: {
    ...mapState('auth', ['user']),
  },

  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchSeasons', 'updateMembership', 'getDescountMemberships', 'sendAuthorization', 'cancelOwnerBooking', 'fetchOwnerBookingFilter', 'sendSpecialNotes', 'fetchOwnerBookingId','sendChangeFolioReserve', 'fetchCancelOwnerBookingInfo', 'fetchBookingsPenalities' ]),
    ...mapMutations('fivesClubCatalogs', ['setSelectedMembership', 'setSeasons', 'setCutoffs', 'setOwnerBookingToEdit', 'setOwnersBookingsId', 'changeStatusOwnerBooking' ]),
	stringBenefits( arr ){
			if (arr.length === 0) {
				return ''; // Si el array está vacío, devuelve una cadena vacía.
			}
			
			if (arr.length === 1) {
				return arr[0]
			}
			
			if(arr.length > 1){
				
				return arr.slice(0, -1).join('| ') + ' | ' + arr.slice(-1)
			}
	},
    async changeStatus(item, event) {
      item.isLoading = true;
      const [blackouts, benefits, cuttoffs] = createArraysToSaveAMembership(
        item.blackouts,
        item.benefits,
        item.Cutoff
      );
      const payload = {
        idUser: this.user.idUser,
        idMembership: item.id,
        idType: item.typeMembershipsId,
        name: item.name,
        paymentType: item.paymentType,
        returnType: item.returnType,
        excludeCalculo: Boolean(item.excludeFeeCalculation),
        showReport: Boolean(item.showReport),
        status: Boolean(event),
        //arreglos
        benefits,
        blackout: blackouts,
        cutoff: cuttoffs,
      };
      const { status, message } = await this.updateMembership(payload);
      item.isLoading = false;
      if (status) {
        showAlertMessage(
          "Ok: Status actualizado",
          "InfoIcon",
          message,
          "success",
          4000,
          "bottom-right"
        );
      }
    },
    pendingClass(status){
      if (status == "1") return 'btn-outline-success' // Pendiente Confirmación
      if (status == "5") return 'btn-outline-warning' // Pendiente Modificación
      if (status == "6") return 'btn-outline-danger' // Pendiente Cancelación
    },
    goToEditBooking(booking) {
      const { id } = booking
      const payload = payloadBookigOwner(booking)
      this.setOwnerBookingToEdit(payload)
      return this.$router.push({ name: "edit-owner-booking", params: { idBooking: id }, })
    },
    async goToCancel(item) {
      const penality = await this.fetchBookingsPenalities(item.id)
      let dataRsv = {
        motiveCancel: null,
        cancelNote: '',
        penaltyNights: Boolean(item?.nights) ? (item?.nights < 3 ? item.nights : 3 ) : 0,
        extraCancelNote: '',
        requiresPenalty: penality
      }

      if (item.status == 6) {
        const response = await this.fetchCancelOwnerBookingInfo(item.id)
        if (response) {
          dataRsv.motiveCancel = response.cancelMotive,
          dataRsv.cancelNote = response.cancelRequestNotes,
          dataRsv.penaltyNights = response.penaltynights
        }
      }
      this.cancelRsv = dataRsv
      this.$root.$emit("bv::show::modal", "modal-cancelReserv" + item.id)
    },
    observationsShow(item) {
      this.originalnote = item.specialnotes
      this.$root.$emit("bv::show::modal", "modal-observations" + item.id)
    },
    addFolioShow(item) {
      this.folioInfo = item.reservanumber
      item.folio=item.reservanumber
      item.toEdit=false
      this.$root.$emit("bv::show::modal", "modal-folio" + item.id)
    },
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale)
    },
    async cancelRsvOwner(data){
      this.isCancelingRsv = true
      const response = await this.cancelOwnerBooking(data)
      if (response) {
        const { status, message } = response
        this.updateInfo(data)
        this.msgCancelBooking(status, message, data.isCxl)
        this.isCancelingRsv = false
        this.$root.$emit('bv::hide::modal', 'modal-cancelReserv' + data.id)
        this.cancelRsv = null
      } else this.isCancelingRsv = false
    },
    msgCancelBooking(status, message, isCxl){
      const msg = isCxl == 0 ? 'Se ha solicitado la cancelación de la reserva' : isCxl == 1 ? 'Se canceló la reserva' : 'Se ha restaurado la reserva'
      const title = isCxl == 0 ? 'Solicitud enviada' : isCxl == 1 ? 'Cancelación realizada' : 'Reserva restaurada'
      if (status) return showAlertMessage( `Ok: ${title}`, 'InfoIcon', msg, 'success', 4000, 'bottom-right' )
      else return showAlertMessage( 'Error: No se pudo cancelar la reserva','InfoIcon', message, 'danger', 4000, 'bottom-right' )
    },
    openModalAuthorize(item) {
      this.$root.$emit('bv::show::modal', 'authorize-pend-rsv' + item.id)
    },
    async autorizeRSV(data){
      this.isAuthorizing = true
      const { isCancel, note, id, isReactivate } =  data
      const payload = { idOwnerBooking: id, AuthorizationBy: this.user.idUser, noteAuthorization: note, isCancel, isReactivate }
      const response = await this.sendAuthorization(payload)
      if (response) {
        const { status, message } = response
        this.updateInfo(data)
        if(!isCancel) this.msgAuthorizeBooking(status, message, isReactivate)
        if(isCancel) this.msgCancelBooking(status, message)
        this.isAuthorizing = false
        this.$root.$emit('bv::hide::modal', 'authorize-pend-rsv' + data.id)
        this.setOwnerBookingToEdit(null)
      } else this.isAuthorizing = false
    },
    msgAuthorizeBooking(status, message, isReactivate){
      if(status) showAlertMessage( `Ok: Se ${isReactivate != 0 ? 'reactivó':'autorizó'} la reserva`, 'InfoIcon', message, 'success', 4000, 'bottom-right' )
      else if(status) showAlertMessage( 'Ok: Se autorizó la reserva', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
      else showAlertMessage( 'Error: No se autorizó la reserva', 'InfoIcon', message, 'warning', 4000, 'bottom-right' )
    },
    async observationsDetail(data) {
      this.loadingInfoDetail = true
      const payload = {
        idBooking: data.id,
        SpecialNote: data.specialnotes,
      }
      const { status } = await this.sendSpecialNotes(payload)
      if (status) {
        const newdata = await this.fetchOwnerBookingId(data.id)
        // Al traer un arreglo, solicito siempre el primer objeto ya que se solicita por id
        data = newdata[0]
      }
      this.loadingInfoDetail = false
    },

    async folioDetail(data) {
      this.loadingInfoDetail = true
      const payload = {
        idBooking: data.id,
        folioReserva: data.folio,
      }
      const { status } = await this.sendChangeFolioReserve(payload)
      if (status) {
        this.updateInfo(data)
      }
      this.loadingInfoDetail = false
    },

    async updateInfo(data) {
      data.isLoading = true
      this.loadingInfoDetail = true
      const newdata = await this.fetchOwnerBookingId(data.id)
      // Al traer un arreglo, solicito siempre el primer objeto ya que se solicita por id
      data = newdata[0]
      this.setOwnersBookingsId(data)
      this.loadingInfoDetail = false
      data.isLoading= false
    },
    canAuthBookings(item){
      return this.can('fivesclub_owner_booking_show_booking_auth_action_button_edit') && item.needAuthorization && item.status == 4
    },
 
	canRequestBookingCancellation(){
      return this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_cancel_request') && !this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_cancel')
    },
	canCancelBooking(){

		const canCancel = this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_cancel')
		const canRequestCancel = this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_cancel_request')

		if ( !canCancel && !canRequestCancel) {
    		return { can: false, text: ''}
		}

		if ( canCancel && canRequestCancel) {
    		return { can: true, text: 'Cancelar'}
		}
		
		if ( canCancel ) {
    		return { can: true, text: 'Cancelar'}			
		}
		
		if( canRequestCancel ){
    		return { can: true, text: 'Solicitar cancelación'}			
		}
	},
  },
};
</script>
<style scoped>
.small-text {
  font-size: 12px;
}

span.badge.badge-secondary.badge-pill {
    line-height: 12px;
}
.popSize{
  width: 350px!important;
}
.dates-checkInOut{
  font-size: .9rem;
}
.text-foliorsv{
  font-weight: 600;
  text-decoration: underline #ff9f43  2px;
}
.minHTable{
  min-height: 350px;
}
.reserva-number{
  color: rgb(45, 200, 63);
}
.no-reserva{
  color: crimson;
}
</style>

