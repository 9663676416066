<template>
  <div class="list-filters">

    <!-- Filtro Inicial y encabezado -->
    <div class="mb-0 p-1" >
      <b-row >
        <!-- <b-col md="8">
          <strong class="title">Filtros de Reservas</strong><br>
        </b-col> -->
        <b-col md="12" class="text-right">
          <div class="text-right">
            <b >Total de Registros: </b>
            <b-badge variant="success" >
              <b-spinner label="Loading..." small v-if="isLoadingOwnerBookings"/>
              <span v-else>{{ownersBookings.length}}</span>
            </b-badge>
          </div>
          <!-- ver más <feather-icon icon="ChevronDownIcon" class="mr-25" /> -->
        </b-col>

        <b-col md="6">
          <b-row>
            <b-col md="4">
              <label>Numero de registros</label>
              <v-select
                v-model="porPagina"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector"
                @input="changePerPage"
              />
            </b-col>
            <b-col>
              <label>Buscar en tabla</label>
              <div class="d-flex">
                <b-form-input
                  v-model="filter.queryOwnersBookins"
                  class="mr-1"
                  placeholder="Buscar..."
                  type="search"
                  @input="setQueryFilter(filter.queryOwnersBookins.trim())"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6">
          <b-row>
            <b-col cols="4">
              <div class="d-flex mt-2">
                <b-button variant="primary" v-b-toggle.advanced-filters block>
                  Filtros <feather-icon :icon="advancedFilters ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </b-button>
              </div>
            </b-col>

            <b-col cols="4">
              <div class="mt-2">
                <b-dropdown no-caret right variant="primary" block @click="setResortPrefilter" v-b-toggle.advanced-filters>
                  <template #button-content>
                    Reportes <b-spinner small v-if="isLoadingExcelOwnerBookings"/>
                  </template>
                  <b-dropdown-item variant="primary"
                    :disabled="!searchFilter.resort"
                    :href="attrLink + '/FivesClub/prearrivals' + '/' + searchFilter.filterByDates +'/' + searchFilter.resort + '/0' + '/' + searchFilter.fechaInicio + '/' + searchFilter.fechaFin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b-icon-file-earmark-ruled-fill scale="0.8px" variant="primary"/>Pre arrivals
                  </b-dropdown-item>
                  <b-dropdown-item variant="primary"
                    :disabled="!searchFilter.resort"
                    :href="attrLink + '/FivesClub/dailyreport/' + searchFilter.resort + '/' + today"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <f<b-icon-file-earmark-ruled-fill scale="0.8px" variant="primary"/>Daily report
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="isLoadingExcelOwnerBookings" @click="getExcel">
                    <b-icon-file-earmark-ruled-fill scale="0.8px" variant="success"/>Excel <b-spinner small v-if="isLoadingExcelOwnerBookings"/>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>

            <b-col cols="4" v-if="searchFilter.type || ownersBookings.length">
              <div class="d-flex mt-2">
                <b-button @click="resetOwnersBookingsFilter" variant="warning" block> Reset</b-button>
              </div>
            </b-col>
            <!-- <label class="text-red pl-1"><strong>{{ !searchFilter.resort ? 'Se requiere el filtro "Resort" para descargar documentos*' : ''}}</strong></label> -->
          </b-row>
        </b-col>
      </b-row>
    </div>

    <!-- Filtros Avanzados -->
    <b-collapse id="advanced-filters" v-model="advancedFilters" >
      <hr>
      <div class="p-1">
        <!-- <div class="clear"></div> -->
        <validation-observer tag="form" v-slot="{ invalid }" >
          <b-form @submit.prevent="manageSearch">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="3">
                    <b-form-group>
                      <label class="p-0 m-0">Filtros por fecha</label>
                        <b-form-select v-model="searchFilter.filterByDates" :options="searchDatesOptions" />
                      <!-- <b-form-checkbox v-model="searchFilter.filterByDates"
                      name="check-button" switch/> -->
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <label>Fecha Inicial</label>
                    <b-form-input
                      type="date"
                      :disabled="!searchFilter.filterByDates"
                      v-model="searchFilter.fechaInicio"
                    />
                  </b-col>

                  <b-col md="3">
                    <label>Fecha Final</label>
                    <b-form-input
                      type="date"
                      :disabled="!searchFilter.filterByDates"
                      v-model="searchFilter.fechaFin"
                    />
                  </b-col>
                  <b-col class="label-text" md="3">
                    <b-row>
                      <b-col md="">
                        <b-form-group label="Origen">
                          <b-dropdown id="dropdown-form" ref="dropdown" variant="outline-secondary" block menu-class="w-100">
                            <template #button-content>
                              <span>{{ origin }}</span>
                            </template>
                            <b-dropdown-form>
                              <b-form-group>
                                <b-row>
                                  <b-col class="pr-0 text-center"><b-form-checkbox v-model="searchFilter.fromWeb" name="check-button" switch/></b-col>
                                  <b-col class="pl-0" md="6"><label>Por Web</label></b-col>
                                </b-row>
                              </b-form-group>
                              <b-dropdown-divider/>
                              <b-form-group>
                                <b-row>
                                  <b-col class="pr-0 text-center"><b-form-checkbox v-model="searchFilter.fromAx" name="check-button" switch @change="checkIsByHistory(searchFilter.fromAx, 'ax')"/></b-col>
                                  <b-col class="pl-0" md="6"><label>Por AX</label></b-col>
                                </b-row>
                              </b-form-group>
                              <b-dropdown-divider/>
                              <b-form-group>
                                <b-row>
                                  <b-col class="pr-0 text-center"><b-form-checkbox v-model="searchFilter.fromSalescore" name="check-button" switch @change="checkIsByHistory(searchFilter.fromSalescore, 'salescore')"/></b-col>
                                  <b-col class="pl-0" md="6"><label>Desde Salescore</label></b-col>
                                </b-row>
                              </b-form-group>
                            </b-dropdown-form>
                          </b-dropdown>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="9">
                <b-row>
                  <b-col class="label-text" md="4"> <!-- Filtro -->
                    <validation-provider name="filtro" >
                      <b-form-group slot-scope="{ valid, errors }" label="Tipo de filtro*" >
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="searchFilter.type"
                          :clearable="false"
                          @change="handlerType"
                        >
                          <option :value="null">Seleccione un tipo de filtro</option>
                          <option
                            v-for="filterOwnersBookin in filtro"
                            :key="filterOwnersBookin.id"
                            :value="filterOwnersBookin.value"
                          >
                            {{ filterOwnersBookin.name }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="" v-if="searchFilter.type === 'resort'">
                    <validation-provider name="resort" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Resort">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="searchFilter.resort"
                          :clearable="false"
                          @change="setNumberHouse"
                        >
                          <option :value="null" disabled>Seleccione una opción</option>
                          <option v-for="h in hotels" :key="h.id" :value="h.id">
                            {{ h.name }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="" v-if="searchFilter.type === 'resort'">
                    <validation-provider name="número vivienda" rules="">
                      <b-form-group slot-scope="{ valid, errors }" label="Vivienda">
                        <b-form-input
                          v-model="searchFilter.numHouse"
                          @keypress="onlyNumber"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Número de vivienda"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'numberHousing'">
                    <validation-provider name="número vivienda" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Vivienda">
                        <b-form-input
                          v-model="searchFilter.numberHousing"
                          @keypress="onlyNumber"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Número de vivienda"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'idStatus'">
                    <validation-provider name="status" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Status">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="searchFilter.status"
                          :clearable="false"
                        >
                          <option disabled value="null">Seleccione una opción</option>
                          <option
                            v-for="status in statuscontract"
                            :key="status.id"
                            :value="status.id"
                          >
                            {{ status.name }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'email'">
                    <validation-provider name="Correo propietario" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Correo propietario"
                      >
                        <b-form-input
                          v-model="searchFilter.email"
                          type="email"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="mail@domain.com"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'invitado'">
                    <validation-provider name="Nombre o apellido" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Nombre o apellido"
                      >
                        <b-form-input
                          v-model="searchFilter.nameLastname"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Nombre o apellido"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'propietario'">
                    <validation-provider name="Nombre o apellido" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Nombre o apellido"
                      >
                        <b-form-input
                          v-model="searchFilter.nameOwner"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Nombre o apellido"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'reservetype'">
                    <validation-provider name="Tipo de reserva" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Tipo de reserva"
                      >
                        <b-form-select
                          v-model="searchFilter.reservetype"
                          :clearable="false"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Tipo de reserva"
                        >
                          <option :value="null" disabled>Seleccione un tipo de reserva</option>
                          <option
                            v-for="reserva in tipoReserva"
                            :key="reserva.id"
                            :value="reserva.id"
                          >
                            {{ reserva.name }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'occupanttype'">
                    <validation-provider name="Tipo de Ocupante" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Tipo de Ocupante"
                      >
                        <b-form-select
                          v-model="searchFilter.occupanttype"
                          :state="errors[0] ? false : valid ? true : null"
                          :clearable="false"
                          placeholder="Tipo de Ocupante"
                        >
                          <option :value="null" disabled>Seleccione un tipo de ocupante</option>
                          <option
                            v-for="ocupante in tipoOcupante"
                            :key="ocupante.id"
                            :value="ocupante.id"
                          >
                            {{ ocupante.name }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'membership'">
                    <validation-provider name="membresía" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Membresia">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="searchFilter.membership"
                          :clearable="false"
                        >
                          <option disabled value="null">Seleccione una opción</option>
                          <option
                            v-for="membership in membershipsOptions"
                            :key="membership.id"
                            :value="membership.id"
                          >
                            {{ membership.code }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'benefit'">
                    <validation-provider name="benefit" rules="required">
                      <b-form-group slot-scope="{ valid, errors }" label="Beneficio">
                        <b-form-select
                          :state="errors[0] ? false : valid ? true : null"
                          v-model="searchFilter.benefit"
                          :clearable="false"
                        >
                          <option disabled value="null">Seleccione una opción</option>
                          <option
                            v-for="benefitOption in benefitOptions"
                            :key="benefitOption.id"
                            :value="benefitOption.id"
                          >
                            {{ benefitOption.name }}
                          </option>
                        </b-form-select>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'folio'">
                    <validation-provider name="Código de Confirmación" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Código de Confirmación"
                      >
                        <b-form-input
                          v-model="searchFilter.folio"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Código de Confirmación"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="4" v-if="searchFilter.type === 'order'">
                    <validation-provider name="orden" rules="required">
                      <b-form-group
                        slot-scope="{ valid, errors }"
                        label="Número de Orden"
                      >
                        <b-form-input
                          v-model="searchFilter.order"
                          :state="errors[0] ? false : valid ? true : null"
                          placeholder="Número de Orden"
                        />
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col class="text-right">
                <b-button
                  class="button-margin"
                  type="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  :disabled="invalid"
                > Buscar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-collapse>

    <hr>

    <!-- Filtros Rápidos -->
    <b-row>
      <b-col md="3" class="mb-1 pr-0">
        <strong class="title pl-1 pr-1">Filtros rápidos</strong>
        <b-button
          size="sm"
          :disabled="isLoadingOwnerBookings"
          class="text-right"
          @click="searchByStatuses(0)"
          variant="warning"
          v-b-tooltip.hover.right
          title="Reestablecer filtros rápidos"
        ><feather-icon size="12" icon="RefreshCcwIcon" class="mr-40"/>
        </b-button>
      </b-col>
      <b-col md="9" class="pl-0">
        <b-row align-v="center">
          <b-col class="text-center">
            <b-form-radio-group
              :disabled="isLoadingOwnerBookings"
              v-model="searchFilter.fastFilter"
              :options="statusBookings"
              class="text-center ml-1 mb-1"
              value-field="id"
              text-field="name"
              @input="searchByStatuses(searchFilter.fastFilter)"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="mb-1 fast-filters" v-if="searchFilter.currentFilter">
      <hr>
      <strong class="ml-1 label-text">{{ searchFilter.currentFilter }}</strong>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapMutations, mapState, mapActions } from 'vuex'
import { showAlertMessage, currentDate } from '@/helpers/helpers'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { filtroBooking, reservetype, occupanttype ,statusOwnerBooking } from '@/data/fivesClub'
import { makeParamsFilterOwnersBookings } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
	async mounted(){
    // console.log(this.$route.matched[this.$route.matched.length - 2].name)

		if (this.ownerBookingFilters) this.searchFilter = structuredClone(this.ownerBookingFilters)
		else {
			if(this.ownersBookings.length==0) {
				this.searchFilter.filterByDates = 'createdate'
				const response = await this.handlerFilter()
				this.searchFilter.filterByDates = null
			}
		}
	},
	directives: {
		Ripple,
	},
  	mixins: [utils],
	props: {
		perPage: {
			type: Number,
			required: true,
		},
		perPageOptions: {
			type: Array,
			required: true,
		},
		isLoadingExcelOwnerBookings: {
			type: Boolean,
			required: true
		},
		isLoadingOwnerBookings: {
			type: Boolean,
			required: true
		},
		ownerBookingsData: {
			type: Array,
			required: true
		},
		originalOwnersBookingData: {
			type: Array,
			required: true
		}
	},
	components: {
		vSelect,
		AppCollapse,
		AppCollapseItem,
	},
	data() {
    return {
      attrLink: process.env.VUE_APP_IMG_SRC_API,
      porPagina: this.perPage,
      filtro: filtroBooking.sort(function (a, b) {
        if (a.name < b.name) {return -1;}
        if (a.name > b.name) {return 1; }
        return 0
      }),
      tipoReserva: reservetype,
      tipoOcupante: occupanttype,
      statuscontract: statusOwnerBooking,
      today: currentDate(),
      advancedFilters:false,
      fastFiltersPage:1,
      searchFilter: {
        all: false,
        benefit: null,
        currentFilter: null,
        DownExcel: false,
        email: "",
        fastFilter:null,
        fechaFin: currentDate(),
        fechaInicio: currentDate(),
        filterByDates: null,
        folio: null,
        fromAx: false,
        fromSalescore: false,
        fromWeb: false,
        isCheckIn: false,
        isCheckOut: false,
        membership: null,
        nameLastname: "",
        nameOwner:null,
        numberHousing:"",
        numHouse: null,
        occupanttype: null,
        order: null,
        reservetype: null,
        resort: null,
        status: null,
        type: null
      },
      searchDatesOptions: [
        {value: null, text: 'Sin fechas'},
        {value: 'datein', text: 'Llegada'},
        {value: 'dateout', text: 'Salida'},
        {value: 'range', text: 'Llegada y Salida'},
        {value: 'createdate', text: 'Creación'},
      ],
      statusBookings: [
        { id: '2', name: 'Confirmada', page: 1, variant: "success"},
        { id: '3', name: 'Cancelada', page: 1, variant: "danger"},
        { id: '4', name: 'Por Autorizar', page: 1, variant: "info"},
        { id: '1', name: 'Pendiente Confirmación', page: 2, variant: "outline-success" },
        { id: '5', name: 'Pendiente Modificación', page: 2, variant: "outline-warning" },
        { id: '6', name: 'Pendiente Cancelación', page: 2, variant: "outline-danger" },
      ],

    }
  },
  computed: {
    ...mapState("fivesClubCatalogs", ["filter", "memberships", 'membershipsOptions','benefitOptions','ownerBookingFilters']),
    ...mapState("start", ["hotels"]),
     ...mapState("fivesClubCatalogs", ["ownersBookings"]),
    titleDaily(){
      if (this.searchFilter.resort) return 'Get your daily report here'
      else return 'Please select a resort first'
    },
    origin(){
      const {fromWeb, fromAx, fromSalescore} = this.searchFilter
      return `${fromWeb ? 'Web':'Sales'} ${fromAx ? '- AX' : fromSalescore ? '- Sales':''}`
    },
    filteringBy(){
      const {type, resort, numHouse, numberHousing, status, email, nameLastname, nameOwner, reservetype, occupanttype, membership, benefit, folio, order} = this.searchFilter
      if (type == 'resort'){
        if (resort && numHouse) return `Vivienda ${numHouse} de ${this.hotels.find(hotel => hotel.id == resort)?.name}`
        if (resort) return this.hotels.find(hotel => hotel.id == resort)?.name
        if (numHouse) return `Vivienda ${numHouse}`
      }
      if (type == 'numberHousing' && numberHousing) return numberHousing
      if (type == 'idStatus' && status) return this.statuscontract.find(estado => estado.id == status)?.name
      if (type == 'email' && email) return email
      if (type == 'invitado' && nameLastname) return nameLastname
      if (type == 'propietario' && nameOwner) return nameOwner
      if (type == 'reservetype' && reservetype) return this.tipoReserva.find(reserva => reserva.id == reservetype)?.name
      if (type == 'occupanttype' && occupanttype) return this.tipoOcupante.find(occupant => occupant.id == occupanttype)?.name
      if (type == 'membership' && membership) return this.membershipsOptions.find(member => member.id == membership)?.code
      if (type == 'benefit' && benefit) return this.benefitOptions.find(benefits => benefits.id == benefit)?.code
      if (type == 'folio' && folio) return folio
      if (type == 'order' && order) return order
    },
    actualFilterName(){
      const nameFilter = this.filtro.find( filter => filter.value == this.searchFilter.type)
      const {fromWeb, fromAx, fromSalescore, filterByDates, fechaInicio, fechaFin, isCheckIn, isCheckOut} = this.searchFilter
      let origin = ''
      let dates = ''

      const firstFilter = nameFilter ? `por ${nameFilter?.name}: ${this.filteringBy}  ` : ''
      const webFilter = fromWeb ? `de Web Propietarios ` : ''

      if (fromAx) origin = `desde AX `
      else if (fromSalescore) origin = `desde Salescore `
      else origin = ''

      if (!!filterByDates) {
        const typeDates = this.searchDatesOptions.find(option => option.value == filterByDates)
        dates = `con fechas de ${typeDates?.text || filterByDates} del ${fechaInicio || ''} al ${fechaFin || ''}`
      }

      if (firstFilter != '' || webFilter != '' || origin != '' || dates != '') return `Filtrando ${firstFilter} ${webFilter} ${origin} ${dates} `
      else return null
    }
  },
  methods: {
    ...mapMutations("fivesClubCatalogs", ["setQueryFilter", 'setOwnersBookings','setOwnersBookingsFilters']),
    ...mapActions("fivesClubOwnersBookins", ["fetchMemberships"]),
    handlerType(type){
      const originalState = this.$options.data().searchFilter
      const newState = this.$data.searchFilter

      let result = {...originalState } // reseto los valores previos del state local
      result.type = type // pongo el type
      // para las fechas mantener filtros locales para fechas
      result.fechaInicio = newState.fechaInicio
      result.fechaFin = newState.fechaFin
      result.filterByDates = newState.filterByDates

      this.searchFilter = result
    },
    searchByStatuses(status){
      if(this.ownersBookings?.length == 0 || this.searchFilter.type == 'idStatus') {
        if (status != 0 ){
          this.searchFilter.type = 'idStatus'
          this.searchFilter.status = status
          this.handlerFilter()
        } else this.resetOwnersBookingsFilter()
      } else {
        const filterData = this.originalOwnersBookingData?.filter(bookings => bookings.status == status)
        const payload = { filterData, status }
        this.$emit('set-filtered-table-items', payload)
        if (status == '0') this.searchFilter.fastFilter  = null
      }
      this.setOwnersBookingsFilters(structuredClone(this.searchFilter))
    },
    changePerPage(perPage) {
      this.$emit("change-per-page", perPage)
      this.porPagina = perPage
    },
    checkIsByHistory(check, from){
      if(from === 'ax' && check ){
        this.searchFilter.fromSalescore = false
      }
      if(from === 'salescore' && check){
        this.searchFilter.fromAx = false
      }
    },
    handlerFilter() {
      const payload = this.checkIfValidFilter()
      if (payload) {
        this.searchFilter.currentFilter = this.actualFilterName
        this.setOwnersBookingsFilters(structuredClone(this.searchFilter))
        this.$emit("get-owners-bookings", payload)
      }
    },
    checkIfValidFilter(){
      const { filterByDates } = this.searchFilter
      if(!!filterByDates){
        const datesAreValid = this.datesAreValid()
        if( !datesAreValid ) return showAlertMessage( "Fechas incorrectas", "BellIcon", `😡 Fecha fin no debe ser menor a fecha de inicio`, "danger", 4000, "bottom-right" )
      }
      const payload = makeParamsFilterOwnersBookings(this.searchFilter)
      const payloadInvalid = JSON.stringify(payload) === '{}'

      if (payloadInvalid) return showAlertMessage( "Filtro incompleto", "BellIcon", `Elija un tipo de filtro o marque uno de los switch`, "danger", 4000, "bottom-right" )
      else return payload
    },
    manageSearch(){
      if (this.searchFilter.searchFilter) this.searchFilter.searchFilter = null
      this.advancedFilters = false
      this.searchFilter.DownExcel = false
      const payload = { filterData: [], status: 0 }
      this.$emit('set-filtered-table-items', payload)
      this.handlerFilter()
    },
    getExcel(){
      this.searchFilter.DownExcel = true
      const payload = this.checkIfValidFilter()
      if (payload) {
        this.setOwnersBookingsFilters(structuredClone(this.searchFilter))
        this.$emit("get-excel-owners-bookings", payload)
      }
    },
    setNumberHouse() {
      this.searchFilter.numHouse = null
    },
    swipeFilter(page){
      this.fastFiltersPage = page
    },
    datesAreValid(){
      const { fechaInicio, fechaFin } = this.searchFilter
      return moment(fechaFin).isSameOrAfter(fechaInicio, "day" )
    },
    setResortPrefilter(){
      this.searchFilter.type = 'resort'
      showAlertMessage( "Seleccione resort", "BellIcon", `Seleccione un resort y, de ser necesario, un rango de fechas`, "info", 4000, "bottom-right" )
    },
    resetOwnersBookingsFilter(){
      this.searchFilter.all = false,
      this.searchFilter.benefit = null,
      this.searchFilter.currentFilter = null,
      this.searchFilter.DownExcel = false,
      this.searchFilter.email = "",
      this.searchFilter.fastFilter =null,
      this.searchFilter.fechaFin = currentDate(),
      this.searchFilter.fechaInicio = currentDate(),
      this.searchFilter.filterByDates = null,
      this.searchFilter.folio = null,
      this.searchFilter.fromAx = false,
      this.searchFilter.fromSalescore = false,
      this.searchFilter.fromWeb = false,
      this.searchFilter.isCheckIn = false,
      this.searchFilter.isCheckOut = false,
      this.searchFilter.membership = null,
      this.searchFilter.nameLastname = "",
      this.searchFilter.nameOwner =null,
      this.searchFilter.numberHousing ="",
      this.searchFilter.numHouse = null,
      this.searchFilter.occupanttype = null,
      this.searchFilter.order = null,
      this.searchFilter.reservetype = null,
      this.searchFilter.resort = null,
      this.searchFilter.status = null,
      this.searchFilter.type = null
      this.setOwnersBookingsFilters(null)
      this.setOwnersBookings([])
      this.$emit('reset-owners-booking')
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
/*

 */
.head-filter{
  display: flex;
  justify-content: space-between;
  margin-block-end: 1rem;
}
.buttons-filter {
  display: flex;
  justify-content: space-between;
}
.isCentered {
  align-content: center;
  align-items: center;
}
.isSpinner {
  padding: 2rem;
}
.filters {
  padding-bottom: 2rem;
}
.paddler {
  padding-left: 1rem;
}

.filters {
  border: solid 1px #b8c2cc;
  padding: 12px 12px 13px 12px !important;
  margin: 0 0 16px 0;
}
.text-red{
  color: red;
}
.clear{
  clear: both;
}
.button-margin{
  margin-top: 1.75rem;
}

.title{
  font-size: medium;
}
.bg-gray{
  border: 2px solid #eee !important;
  background: #eee;
}

.fast-filters{
  vertical-align: middle;
}
.fast-filters-button{
  padding: 0.3rem;
  font-size:x-small;
}
.small-text{
  font-size:x-small
}

.label-text{
  font-size: 0.857rem;
}
.not-that-big{
  width: fit-content;
}
.margin-filters{
  margin: 0.4rem;
  justify-content: center;
}
.no-border{
  border: 0;
}
.origin-options{
  width: 9rem;
}

.dropdown-words{
  /* min-width: fit-content; */
  /* inline-size: min-content; */
  overflow-wrap: break-word !important;
  word-break: break-all !important;
}
</style>